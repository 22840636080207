import React, { useState } from "react";
import styled from "styled-components";
import castscreenshot from "./img/castscreenshot.png";
import listmescreenshot from "./img/listmescreenshot.png";
import pdscreenshot from "./img/pdscreenshot.png";
import listmelogo from "./img/listmelogo.webp";
import castlogo from "./img/castlogo.webp";
import pdlogo from "./img/pdlogo.png";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator";
import firebase from "firebase/app";
import "firebase/firestore";
import { HashLink as Link } from "react-router-hash-link";
import { HashRouter as Router } from "react-router-dom";

const Content = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0e0e19;
  background-image: linear-gradient(360deg, #0e0e19 0%, #2b3541 80%);
`;

const TopBar = styled.div`
  height: 35px;
  width: 100%;
  background: black;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  overflowx: hidden;
`;

const LeftIcon = styled.button`
  height: 35px;
  width: 35px;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: none;
  :hover {
    svg {
      transition: 200ms;
      fill: rgba(255, 255, 255, 0.7);
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  min-width: 250px;
  min-height: 100px;
  border-radius: 0px 0px 10px 0px;
  background: rgba(14, 14, 25, 0.75);
  top: 35px;
  left: 0;
`;

const MenuItem = styled.a`
  padding: 5px;
  background: transparent;
  margin: 0px 10px;
  border-bottom: 1px solid #29323e;
  display: block;
  text-decoration: none;
  :hover {
    background: rgba(0, 0, 0, 0.2);
    transition: 500ms;
    cursor: pointer;
  }
`;

const MenuText = styled.div`
  font-size: 14px;
  color: white;
  font-family: arial, sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const MenuSocialArea = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
`;

const SocialButton = styled.a`
  cursor: pointer;
  text-decoration: none;
  max-width: 10vw;
  max-height: 10vw;
  ${({ large }) =>
    large
      ? `
    height: 24px;
    width: 24px;
  `
      : `
    margin: 0px 15px;
    height: 15px;
    width: 15px;
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    svg {
      transition: 200ms;
      fill: #b4f3ff;
    }
  }
`;

const RightArea = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
`;

const NameText = styled.h1`
  margin: 0;
  padding: 0;
  color: white;
  font-family: arial, sans-serif;
  font-weight: 100;
  font-size: 40px;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ProjectsBtn = styled.div`
  border-radius: 25px;
  height: 25px;
  padding: 0px 50px;
  border: 2px solid #b4f3ff;
  box-shadow: 0px 0px 5px #b4f3ff;
  transition: 500ms;
  cursor: pointer;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    transition: 500ms;
    box-shadow: 0px 0px 10px #b4f3ff;
  }
`;

const Spacer = styled.div`
  height: 15px;
`;

const Line = styled.div`
  height: 1px;
  width: 75vw;
  max-width: 500px;
  border: 1px solid #b4f3ff;
  box-shadow: 0px 0px 5px #b4f3ff;
  margin: 10px 0px;
`;

const BioText = styled.p`
  color: white;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  max-width: 400px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const BioLink = styled.a`
  color: #b4f3ff;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  max-width: 400px;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ProjectsText = styled.p`
  color: white;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const EmailButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    transition: 500ms;
    svg {
      transition: 500ms;
      fill: #b4f3ff;
    }
    p {
      transition: 500ms;
      color: #b4f3ff;
    }
  }
`;

const Projects = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0e0e19;
  background-image: linear-gradient(360deg, #2b3541 0%, #0e0e19 20%);
`;

const ProjectItem = styled.div`
  width: 90vw;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20vh;
  :hover {
    .screenshots {
      transition: 1000ms;
      transform: translateY(-5px);
    }
  }
`;

const ProjectBg = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  min-height: 200px;
  width: 100%;
  margin-top: -200px;
  padding-top: 200px;
  z-index: 0;
`;

const ProjectImg = styled.img`
  max-width: 80vw;
  width: 450px;
  z-index: 1;
  transition: 1000ms;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ProjectTitle = styled.h2`
  color: white;
  font-family: arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const TechArea = styled.div`
  display: flex;
`;

const ProjectsParagraph = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-family: arial, sans-serif;
  font-size: 14px;
  margin: 10px 20px;
  margin-bottom: 30px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ProjectInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
`;

const ProjectLeft = styled.div``;

const AppIcon = styled.img`
  width: 40px;
  height: 40px;
  max-width: 15vw;
  max-height: 15vw;
  border-radius: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Footer = styled.div`
  width: 100%;
  min-height: 200px;
  background: #2b3541;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;

const ContactTitle = styled.h2`
  color: white;
  font-family: arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
`;

const InputArea = styled.div`
  display: flex;
  ${({ horizontal }) =>
    horizontal
      ? `
    justify-content: space-between;
    align-items: center;
  `
      : `
    flex-direction: column
  `};
  margin: 5px 0px;
`;

const InputLabel = styled.label`
  font-size: 12px;
  color: white;
  font-family: arial, sans-serif;
  margin-left: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const TextInput = styled.input`
  height: 35px;
  width: 90vw;
  max-width: 500px;
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid transparent;
  border-radius: 10px;
  color: #b4f3ff;
  outline: none;
  padding-left: 10px;
  :focus {
    border: 2px solid #b4f3ff;
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const TextArea = styled.textarea`
  width: 90vw;
  max-width: 500px;
  height: 150px;
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid transparent;
  border-radius: 10px;
  color: #b4f3ff;
  outline: none;
  padding: 10px;
`;

const SubmitBtn = styled.button`
  height: 35px;
  width: 90vw;
  max-width: 500px;
  align-self: flex-end;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  border-radius: 10px;
  transition: 500ms;
  margin-bottom: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: not-allowed;
  ${({ data }) =>
    data
      ? `
    cursor: pointer;
    background: rgba(0,0,0,0.7);
    color: rgba(255,255,255,1);
    :hover {
      filter: brightness(0.7);
    }
  `
      : []}
`;

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const recaptchaRef = React.createRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({ box: "", message: "" });

  var firebaseConfig = {
    apiKey: "AIzaSyCu3iMqRZvX3vY96YurSt36CiDrxo6AvYg",
    authDomain: "chancesquires-bdddd.firebaseapp.com",
    projectId: "chancesquires-bdddd",
    storageBucket: "chancesquires-bdddd.appspot.com",
    messagingSenderId: "145484369769",
    appId: "1:145484369769:web:be8b5d8538d805d55e37f8",
    measurementId: "G-DTJ3CCZ2J7",
  };
  // Initialize Firebase
  !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app();

  const clearError = () => {
    setTimeout(() => {
      setError({ box: "", message: "" });
    }, 4000);
  };

  const verifyFields = () => {
    if (!name) {
      setError({ box: "name", message: "Please enter your name" });
      clearError();
      return;
    }

    if (!email) {
      setError({ box: "email", message: "Please enter your email" });
      clearError();
      return;
    }

    if (!message) {
      setError({ box: "message", message: "Please enter a message" });
      clearError();
      return;
    }

    if (!validator.isEmail(email.toString())) {
      setError({ box: "email", message: "Please enter a valid email" });
      clearError();
      return;
    }

    firebase
      .firestore()
      .collection("contact_form_chancesquires")
      .add({
        to: "chancesq@gmail.com",
        from: "chancesq@gmail.com",
        message: {
          subject: "New email from your website: chancesquires.com",
          text: `New message from ${name} [${email}]: ${message}`,
        },
      });

    setError({ box: "", message: "message sent!" });
    clearError();
    setEmail("");
    setName("");
    setMessage("");
  };

  const onSubmit = async () => {
    const recaptchaValue = await recaptchaRef.current.getValue();
    if (recaptchaValue) {
      verifyFields();
    } else {
      setError({ box: "", message: "Please complete captcha" });
      clearError();
    }
  };

  return (
    <Router>
      <TopBar>
        <LeftIcon
          aria-label="toggleMenu"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="white"
            >
              <path d="M12 11.293L22.293 1l.707.707L12.707 12 23 22.293l-.707.707L12 12.707 1.707 23 1 22.293 11.293 12 1 1.707 1.707 1 12 11.293z" />
            </svg>
          ) : (
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="white"
            >
              <path
                d="M24 18v1H0v-1h24zm0-6v1H0v-1h24zm0-6v1H0V6h24z"
                fill="#1040e2"
              />
              <path d="M24 19H0v-1h24v1zm0-6H0v-1h24v1zm0-6H0V6h24v1z" />
            </svg>
          )}
        </LeftIcon>
        {menuOpen ? (
          <Menu>
            <Link to="#projectsAnchor" style={{ textDecoration: "none" }}>
              <MenuItem
                aria-label="internal link to projects"
                onClick={() => setMenuOpen(false)}
              >
                <MenuText>Work</MenuText>
              </MenuItem>
            </Link>
            <MenuItem
              aria-label="external link to resume"
              rel="noopener noreferrer"
              href="https://docs.google.com/document/d/15Jcl3lULGGtKVtilQzpP8oDsBpgclEbqIsIzIV5CeZw/edit?usp=sharing"
              target="_blank"
            >
              <MenuText>
                Resume
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={10}
                  height={10}
                  viewBox="0 0 24 24"
                  fill="rgba(255,255,255,0.5)"
                  style={{ marginLeft: 5 }}
                >
                  <path d="M21 13v10H0V4h12v2H2v15h17v-8h2zm3-12H13.012l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07L24 12V1z" />
                </svg>
              </MenuText>
            </MenuItem>
            <Link to="#contactAnchor" style={{ textDecoration: "none" }}>
              <MenuItem
                aria-label="internal link to contact form"
                onClick={() => setMenuOpen(false)}
              >
                <MenuText>Contact</MenuText>
              </MenuItem>
            </Link>
            <MenuSocialArea>
              <SocialButton
                aria-label="external link to linkedin"
                large
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/chancesquires/"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="white"
                >
                  <path d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6S.02 4.881.02 3.5C.02 2.12 1.13 1 2.5 1s2.48 1.12 2.48 2.5zM5 8H0v16h5V8zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V24H24V13.869c0-7.88-8.922-7.593-11.018-3.714V8z" />
                </svg>
              </SocialButton>
              <SocialButton
                aria-label="external link to github"
                large
                rel="noopener noreferrer"
                href="https://github.com/ChanceSQ"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="white"
                >
                  <path d="M12 0C5.374 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23A11.509 11.509 0 0112 5.803c1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576C20.566 21.797 24 17.3 24 12c0-6.627-5.373-12-12-12z" />
                </svg>
              </SocialButton>
              <SocialButton
                aria-label="external link to twitter"
                large
                rel="noopener noreferrer"
                href="https://twitter.com/SquiresChance"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="white"
                >
                  <path d="M24 4.557a9.83 9.83 0 01-2.828.775 4.932 4.932 0 002.165-2.724 9.864 9.864 0 01-3.127 1.195 4.916 4.916 0 00-3.594-1.555c-3.179 0-5.515 2.966-4.797 6.045A13.978 13.978 0 011.671 3.149a4.93 4.93 0 001.523 6.574 4.903 4.903 0 01-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.935 4.935 0 01-2.224.084 4.928 4.928 0 004.6 3.419A9.9 9.9 0 010 19.54a13.94 13.94 0 007.548 2.212c9.142 0 14.307-7.721 13.995-14.646A10.025 10.025 0 0024 4.557z" />
                </svg>
              </SocialButton>
            </MenuSocialArea>
          </Menu>
        ) : (
          []
        )}
        <RightArea>
          <SocialButton
            aria-label="external link to linkedin profile"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/chancesquires/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="white"
            >
              <path d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6S.02 4.881.02 3.5C.02 2.12 1.13 1 2.5 1s2.48 1.12 2.48 2.5zM5 8H0v16h5V8zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V24H24V13.869c0-7.88-8.922-7.593-11.018-3.714V8z" />
            </svg>
          </SocialButton>
          <SocialButton
            aria-label="external link to github profile"
            rel="noopener noreferrer"
            href="https://github.com/ChanceSQ"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="white"
            >
              <path d="M12 0C5.374 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23A11.509 11.509 0 0112 5.803c1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576C20.566 21.797 24 17.3 24 12c0-6.627-5.373-12-12-12z" />
            </svg>
          </SocialButton>
          <SocialButton
            aria-label="external link to twitter profile"
            rel="noopener noreferrer"
            href="https://twitter.com/SquiresChance"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="white"
            >
              <path d="M24 4.557a9.83 9.83 0 01-2.828.775 4.932 4.932 0 002.165-2.724 9.864 9.864 0 01-3.127 1.195 4.916 4.916 0 00-3.594-1.555c-3.179 0-5.515 2.966-4.797 6.045A13.978 13.978 0 011.671 3.149a4.93 4.93 0 001.523 6.574 4.903 4.903 0 01-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.935 4.935 0 01-2.224.084 4.928 4.928 0 004.6 3.419A9.9 9.9 0 010 19.54a13.94 13.94 0 007.548 2.212c9.142 0 14.307-7.721 13.995-14.646A10.025 10.025 0 0024 4.557z" />
            </svg>
          </SocialButton>
        </RightArea>
      </TopBar>
      <Content onClick={() => setMenuOpen(false)}>
        <NameText>Chance Squires</NameText>
        <Line />
        <BioText>
          I am a Senior Software Engineer{" "}
          <BioLink
            aria-label="external link to glossgenius"
            rel="noopener noreferrer"
            href="https://www.glossgenius.com/"
            target="_blank"
          >
            @GlossGenius
          </BioLink>{" "}
          and a Web Development Instructor{" "}
          <BioLink
            aria-label="external link to brainstation"
            rel="noopener noreferrer"
            href="https://brainstation.io/"
            target="_blank"
          >
            @BrainStation
          </BioLink>{" "}
          specializing in React Native and NextJS
        </BioText>
        <Spacer />
        <Link to="#contactAnchor" style={{ textDecoration: "none" }}>
          <EmailButton>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={14}
              viewBox="0 0 24 24"
              fill="white"
              style={{ marginRight: 5 }}
            >
              <path d="M0 3v18h24V3H0zm21.518 2L12 12.713 2.482 5h19.036zM2 19V7.183l10 8.104 10-8.104V19H2z" />
            </svg>
            <ProjectsText>chancesq[at]gmail.com</ProjectsText>
          </EmailButton>
        </Link>
        <Spacer />
        <Link to="#projectsAnchor" style={{ textDecoration: "none" }}>
          <ProjectsBtn>
            <ProjectsText>View my work</ProjectsText>
          </ProjectsBtn>
        </Link>
      </Content>
      <Projects id="projectsAnchor">
        <ProjectItem>
          <ProjectImg
            className="screenshots"
            src={castscreenshot}
            alt="cast screenshot on phone"
          />
          <ProjectBg>
            <ProjectInfo>
              <ProjectLeft>
                <ProjectTitle>Cast</ProjectTitle>
                <TechArea>
                  <ProjectsText>React Native (Expo) |</ProjectsText>
                  <SocialButton
                    aria-label="cast on ios"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/cast/id1538593107"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="white"
                    >
                      <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983C5.97 24.097 2 18.171 2 13.003c0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zM16.778 0c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z" />
                    </svg>
                  </SocialButton>
                  <SocialButton
                    aria-label="cast on google play"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.createyourcast.cast"
                    target="_blank"
                  >
                    <svg
                      width={24}
                      height={24}
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      viewBox="0 0 24 24"
                      fill="white"
                    >
                      <path d="M1.571 23.664l10.531-10.501 3.712 3.701-12.519 6.941a1.563 1.563 0 01-1.532-.011l-.192-.13zm9.469-11.56L1 22.115V2.093l10.04 10.011zm6.274-4.137l4.905 2.719c.482.268.781.77.781 1.314s-.299 1.046-.781 1.314l-5.039 2.793-4.015-4.003 4.149-4.137zM1.46.433A1.563 1.563 0 013.295.195L15.948 7.21l-3.846 3.835L1.46.433z" />
                    </svg>
                  </SocialButton>
                </TechArea>
              </ProjectLeft>
              <AppIcon src={castlogo} alt="cast logo" />
            </ProjectInfo>
            <ProjectsParagraph>
              Cast is a social voting app. I designed, developed, and launched
              Cast on iOS and Android, delivering a seamless user experience.
              From inception to deployment, I managed the entire lifecycle of
              the frontend, ensuring optimal performance, compatibility, and
              intuitive UI/UX.
            </ProjectsParagraph>
          </ProjectBg>
        </ProjectItem>
        <ProjectItem>
          <ProjectImg
            className="screenshots"
            src={listmescreenshot}
            alt="listme screenshot on phone"
          />
          <ProjectBg>
            <ProjectInfo>
              <ProjectLeft>
                <ProjectTitle>ListMe</ProjectTitle>
                <TechArea>
                  <ProjectsText>React Native (Expo) |</ProjectsText>
                  <SocialButton
                    aria-label="listme on ios"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/in/app/listme-app/id1462565783"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="white"
                    >
                      <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983C5.97 24.097 2 18.171 2 13.003c0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zM16.778 0c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z" />
                    </svg>
                  </SocialButton>
                  <SocialButton
                    aria-label="listme on google play"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.listme"
                    target="_blank"
                  >
                    <svg
                      width={24}
                      height={24}
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      viewBox="0 0 24 24"
                      fill="white"
                    >
                      <path d="M1.571 23.664l10.531-10.501 3.712 3.701-12.519 6.941a1.563 1.563 0 01-1.532-.011l-.192-.13zm9.469-11.56L1 22.115V2.093l10.04 10.011zm6.274-4.137l4.905 2.719c.482.268.781.77.781 1.314s-.299 1.046-.781 1.314l-5.039 2.793-4.015-4.003 4.149-4.137zM1.46.433A1.563 1.563 0 013.295.195L15.948 7.21l-3.846 3.835L1.46.433z" />
                    </svg>
                  </SocialButton>
                </TechArea>
              </ProjectLeft>
              <AppIcon src={listmelogo} alt="listme logo" />
            </ProjectInfo>
            <ProjectsParagraph>
              ListMe is a digital ID and wallet. Focused on user experience and
              accessibility, I prioritized seamless navigation and engaging
              visuals. With performance optimization and rigorous testing, I
              delivered a polished cross-platform solution using React Native
              Expo.
            </ProjectsParagraph>
          </ProjectBg>
        </ProjectItem>
        <ProjectItem>
          <ProjectImg
            className="screenshots"
            src={pdscreenshot}
            alt="precision detail screenshot on laptop and phone"
          />
          <ProjectBg>
            <ProjectInfo>
              <ProjectLeft>
                <ProjectTitle>Precision Detail</ProjectTitle>
                <TechArea>
                  <ProjectsText>React |</ProjectsText>
                  <SocialButton
                    aria-label="precision detail (work in progress) on netlify"
                    rel="noopener noreferrer"
                    href="https://pd-dev.netlify.app/#/"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="white"
                    >
                      <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm10 12c0 .685-.07 1.354-.202 2h-3.853a21.373 21.373 0 000-4h3.853c.132.646.202 1.315.202 2zm-.841-4h-3.5c-.383-1.96-1.052-3.751-1.948-5.278A10.048 10.048 0 0121.159 8zm-5.554 0H13V2.342c1.215 1.46 2.117 3.41 2.605 5.658zM11 2.342V8H8.395C8.883 5.752 9.785 3.802 11 2.342zM11 10v4H8.07a18.32 18.32 0 010-4H11zm0 6v5.658c-1.215-1.46-2.117-3.41-2.605-5.658H11zm2 5.658V16h2.605c-.488 2.248-1.39 4.198-2.605 5.658zM13 14v-4h2.93a18.32 18.32 0 010 4H13zM8.289 2.722C7.393 4.249 6.724 6.04 6.341 8h-3.5a10.048 10.048 0 015.448-5.278zM2.202 10h3.853a21.373 21.373 0 000 4H2.202a9.983 9.983 0 010-4zm.639 6h3.5c.383 1.96 1.052 3.751 1.948 5.278A10.048 10.048 0 012.841 16zm12.87 5.278c.896-1.527 1.565-3.318 1.948-5.278h3.5a10.048 10.048 0 01-5.448 5.278z" />
                    </svg>
                  </SocialButton>
                </TechArea>
              </ProjectLeft>
              <AppIcon src={pdlogo} alt="precision detail logo" />
            </ProjectInfo>
            <ProjectsParagraph>
              Precision Detail is a mobile automotive detailer. I led the design
              and development of the website with a mobile first strategy. The
              designs were mocked up in XD and presented to the client for
              approval, then built in React.
            </ProjectsParagraph>
          </ProjectBg>
        </ProjectItem>
      </Projects>
      <Footer id="contactAnchor">
        <ContactTitle>Let's get to know each other!</ContactTitle>
        <InputArea>
          <InputLabel>Name</InputLabel>
          <TextInput
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </InputArea>
        <InputArea>
          <InputLabel>Email</InputLabel>
          <TextInput
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </InputArea>
        <InputArea>
          <InputLabel>Message</InputLabel>
          <TextArea
            placeholder="Message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </InputArea>
        <InputArea horizontal>
          <ProjectsText>{error.message}</ProjectsText>
          <SubmitBtn
            aria-label="submit-form"
            onClick={() => {
              if (name && email && message) {
                onSubmit();
              }
            }}
            data={name && email && message}
          >
            Submit
          </SubmitBtn>
        </InputArea>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcQM98ZAAAAADLAL10r2DAGdONCXKvIQtvSRMXW"
          theme="dark"
          size="normal"
        />
      </Footer>
    </Router>
  );
}

export default App;
